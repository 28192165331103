exports.components = {
  "component---src-pages-404-404-tsx": () => import("./../../../src/pages/404/404.tsx" /* webpackChunkName: "component---src-pages-404-404-tsx" */),
  "component---src-pages-404-index-ts": () => import("./../../../src/pages/404/index.ts" /* webpackChunkName: "component---src-pages-404-index-ts" */),
  "component---src-pages-castiguri-din-videochat-index-tsx": () => import("./../../../src/pages/castiguri-din-videochat/index.tsx" /* webpackChunkName: "component---src-pages-castiguri-din-videochat-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-de-ce-videochat-index-tsx": () => import("./../../../src/pages/de-ce-videochat/index.tsx" /* webpackChunkName: "component---src-pages-de-ce-videochat-index-tsx" */),
  "component---src-pages-despre-noi-index-tsx": () => import("./../../../src/pages/despre-noi/index.tsx" /* webpackChunkName: "component---src-pages-despre-noi-index-tsx" */),
  "component---src-pages-echipa-index-tsx": () => import("./../../../src/pages/echipa/index.tsx" /* webpackChunkName: "component---src-pages-echipa-index-tsx" */),
  "component---src-pages-galerie-index-tsx": () => import("./../../../src/pages/galerie/index.tsx" /* webpackChunkName: "component---src-pages-galerie-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intrebari-frecvente-index-tsx": () => import("./../../../src/pages/intrebari-frecvente/index.tsx" /* webpackChunkName: "component---src-pages-intrebari-frecvente-index-tsx" */),
  "component---src-pages-videochat-de-acasa-index-tsx": () => import("./../../../src/pages/videochat-de-acasa/index.tsx" /* webpackChunkName: "component---src-pages-videochat-de-acasa-index-tsx" */),
  "component---src-pages-videochat-la-studio-venus-index-tsx": () => import("./../../../src/pages/videochat-la-studio-venus/index.tsx" /* webpackChunkName: "component---src-pages-videochat-la-studio-venus-index-tsx" */)
}

