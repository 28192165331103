// gatsby-browser.js
import './src/styles/global.css'
import './src/i18n'

import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { getCookieConsentValue } from 'react-cookie-consent'

export const onRouteUpdate = ({ location }) => {
	if (getCookieConsentValue('gatsby-gdpr-google-analytics') === 'true') {
		initializeAndTrack(location)
	}
}

export const onClientEntry = () => {
	console.log('Studio Venus!')
}
