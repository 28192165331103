import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from './locales/en/translation.json'
import translationRO from './locales/ro/translation.json'
import translationUK from './locales/uk/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      ro: { translation: translationRO },
      uk: { translation: translationUK },
    },
    fallbackLng: 'ro',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      checkWhitelist: true,
    },
    languages: ['en', 'ro', 'uk'],
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
